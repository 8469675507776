<template>
  <div class="collectionAccount" 
  v-loading.fullscreen.lock="loading"
  element-loading-text="拼命加载中"
  element-loading-background="rgba(255, 255, 255, 0.5)">
        <el-tabs v-model="pageInfo.status" @tab-click="handleClick" style="margin-bottom:15px">
            <el-tab-pane label="未收款" name="150"></el-tab-pane>
            <el-tab-pane label="已收款" name="151"></el-tab-pane>
            <el-tab-pane label="已驳回" name="152"></el-tab-pane>
        </el-tabs>
        <el-select clearable v-model="pageInfo.collectionType" placeholder="收款类型" style="width:150px;margin-right:10px" @change="search">
            <el-option 
            label="全部" 
            value=" "
            >
            </el-option>
            <el-option 
            :label="item.value" 
            :value="item.id"
            v-for="item in collectionBillTypeList"
            :key="item.id">
            </el-option>
        </el-select>
        <el-select clearable v-model="pageInfo.autoCreate" placeholder="收款单来源" style="width:150px;margin-right:10px" @change="search">
            <el-option 
            label="全部" 
            value=" "
            >
            </el-option>
            <el-option 
            :label="item.value" 
            :value="item.id"
            v-for="item in collectionSourceList"
            :key="item.id">
            </el-option>
        </el-select>
        <el-input v-model="pageInfo.keyword" placeholder="请输入搜索内容" style="width:150px;margin-right:10px" @keyup.enter.native = "search"/>
        <el-button type="primary" icon="el-icon-search" size="small" @click="search">查询</el-button>
        <div style="margin:10px 0;display:flex;justify-content:space-between">
            <div>
                <el-button v-if="pageInfo.status==150" type="primary" @click="infoBox('添加收款单')">添加收款单</el-button>
                <el-button v-if="pageInfo.status==150" type="primary" @click="changeStatus(151)">标记已收款</el-button>
            </div>
            <div style="display:flex;justify-content:space-around;width:130px">
                <TableDialog :tableList='tableList' @setTableHead="setTableHead"/>
                <el-tooltip content="生成报告" placement="top">
                    <div class="setting el-icon-receiving" @click="report"></div>
                </el-tooltip>
                <el-tooltip content="下载报告" placement="top">
                    <div class="setting el-icon-download" @click="exportTable"></div>
                </el-tooltip>
            </div>
        </div>
        <el-dialog
            title="收款单-下载报告"
            :visible.sync="downReportBox"
            width="width"
            :before-close="downClose">
            <el-table
                :data="downList"
                border
                cell-style="text-align:center"
                header-cell-style="text-align:center;background:#E4E7ED"
                style="width: 100%">
                <el-table-column
                    prop="createTime"
                    label="申请时间"
                    width="width">
                </el-table-column>
                <el-table-column
                    prop="createBy"
                    label="操作者"
                    width="width">
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态"
                    width="width">
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="width">
                    <template slot-scope="scope">
                        <el-button :disabled="scope.row.status=='队列中'||scope.row.status=='下载失败'?true:false" type="text" @click="down(scope.row.fileUrl)">下载</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer">
                <el-button @click="downReportBox = false">取 消</el-button>
                <el-button type="primary" @click="downReportBox = false">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 标记已收款上传部分 -->
        <el-dialog
        title="标记已收款"
        :visible.sync="sign"
        width="30%"
        :before-close="handleClose">
            <span>是否标记为已收款？收款完成后，收款账户将增加对应金额。</span>
            <p style="margin:10px 0">
                <i style="color:red;font-style:normal;">*</i>
                <span>上传收款凭证：</span>
            </p>
            <el-upload
            class="upload-demo"
            :action="action"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            :on-success="uploading"
            list-type="picture"
            :headers="mytoken">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">支持jpg/jpeg/png/bmp,大小不超过5M</div>
            </el-upload>
            
            <span slot="footer" class="dialog-footer">
                <el-button @click="sign = false">取 消</el-button>
                <el-button :loading='dialogLoading' type="primary" @click="affirm">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
        title="查看凭证"
        :visible.sync="detail"
        width="30%"
        >
            <div class="demo-image__preview">
            <el-image
                v-for="(item,index) in fileList"
                :key='index'
                style="width: 100px; height: 100px"
                :src="item" 
                :preview-src-list="fileList">
            </el-image>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="detail=false">取 消</el-button>
                <el-button type="primary" @click="detail=false">确 定</el-button>
            </span>
        </el-dialog>
        <el-image-viewer
        v-if="showBig"
        :on-close="closeViewer"
        :url-list="bigUrl"
        z-index='10000'
        />

        <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%;"
        cell-style="text-align:center"
        header-cell-style="text-align:center;background:#E4E7ED"
        border
        @selection-change="handleSelectionChange">
            <el-table-column
            type="selection"
            width="60">
            </el-table-column>
            <template v-for="item in tableHead">
                <el-table-column
                :key="item.name"
                :label="item.name"
                :width="item.width"
                :prop="item.prop"
                v-if="item.prop!='1'&&item.prop!='2'"
                >
                </el-table-column>
                <el-table-column
                :key="item.name"
                :label="item.name"
                :width="item.width"
                :prop="item.prop"
                v-else-if="item.prop=='2'">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==150">未收款</span>
                        <span v-if="scope.row.status==151">已收款</span>
                        <span v-if="scope.row.status==152">已驳回</span>
                    </template>
                </el-table-column>
                <el-table-column
                :key="item.name"
                :label="item.name"
                :width="180"
                v-else-if="item.prop=='1'"
                >
                    <template slot-scope="scope">
                        <p style="text-align:left">{{scope.row.createBy}}</p>
                        <p style="text-align:left">{{scope.row.createTime}}</p>
                    </template>
                </el-table-column>
            </template>
            <el-table-column
            v-if="pageInfo.status==152"
            header-align="center"
            width="180"
            label="驳回人/驳回时间">
                <template slot-scope="scope">
                    <p>{{scope.row.rejectBy}}</p>
                    <p>{{scope.row.rejectTime}}</p>
                </template>
            </el-table-column>
            <el-table-column
                label="收款人/收款时间"
                :width="180"
                v-if="pageInfo.status==151"
                sortable='true'
                >
                <template slot-scope="scope">
                    <p style="text-align:left">{{scope.row.collectBy}}</p>
                    <p style="text-align:left">{{scope.row.collectTime}}</p>
                </template>
            </el-table-column>
            <el-table-column
            v-if="pageInfo.status==152"
            header-align="center"
            align="center"
            prop="rejectReason"
            width="140"
            label="驳回原因" >
            </el-table-column>
            <el-table-column
            v-if="pageInfo.status!=152"
            header-align="center"
            align="center"
            :label="pageInfo.status==150?'操作':'凭证'"
            >
                <template slot-scope="scope" >
                    <div v-if="scope.row.status==150">
                        <el-button type="text" @click="infoBox('修改',scope.row.id)">修改</el-button>
                        <el-button type="text" v-if="scope.row.autoCreate=='0'" @click="remove(scope.row.id)">删除</el-button>
                        <el-button type="text" v-if="scope.row.autoCreate=='1'" @click="back(scope.row.id)">驳回</el-button>
                    </div>
                    <div v-else-if="scope.row.status==151">
                        <el-button type="text" @click="findDetail(scope.row.id)">查看详情</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- <div class="sum">
            <span>合计</span>
            <span>应收金额：{{sum}}</span>
        </div> -->
        <div style="margin:10px 20px 0 0;text-align:center">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
        <!-- 添加收款单 -->
        <el-dialog
        title="收款单"
        :modal-append-to-body="false"
        :visible.sync="addCollect"
        width="33%"
        :before-close="handleClose">
            <el-form v-loading="dialogLoading" ref="collectInfo" :rules="collectInfoInfoRules" :model="collectInfo" label-width="110px">
                <el-form-item label="收款类型:" prop="collectionType">
                    <el-select :disabled="boxType=='修改'?true:false" filterable placeholder="请选择收款类型" v-model="collectInfo.collectionType" style="width:400px;margin-right:10px">
                        <el-option 
                        :label="item.value" 
                        :value="item.id"
                        v-for="item in collectionBillTypeList"
                        :key="item.id">
                        </el-option>
                    </el-select>
                    <el-button type="text" @click="typeManage=true">类型管理</el-button>
                </el-form-item>
                <el-form-item label="收款账户名称:" prop="financeAccountId">
                    <el-select 
                    placeholder="请选择账户名称" 
                    style="width:400px;margin-right:10px"
                    v-model="collectInfo.financeAccountId">
                        <el-option 
                        :label="item.payAccountName" 
                        :value="item.id"
                        v-for="item in payAccountList"
                        :key="item.id"></el-option>
                    </el-select>
                    <el-button type="text" @click="$router.push('/balaPayAccount')">账户管理</el-button>
                </el-form-item>
                <el-form-item label="应收金额:" prop="amountInCurrency">
                    <el-input 
                    :disabled='disabled'
                    placeholder="请填写应收金额" 
                    v-model="collectInfo.amountInCurrency" 
                    style="width:480px;border:0">
                    <el-button slot="prepend" style="width:80px">{{currency}}</el-button>
                    <el-button slot="append">转RMB金额: {{turnRMB}} 元</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="预计收款时间:" prop="estimateCollectionDate">
                    <el-date-picker
                    :disabled='disabled'
                    :picker-options="pickerOptions"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-model="collectInfo.estimateCollectionDate"
                    type="date"
                    placeholder="选择日期"
                    style="width:480px;margin-right:10px">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="付款账户名称:" prop="payAccountName">
                    <el-input :disabled='disabled' v-model="collectInfo.payAccountName" placeholder="请填写付款账户名称" style="width:480px;margin-right:10px"/>
                </el-form-item>
                <el-form-item label="付款账号:" prop="payAccount">
                    <el-input placeholder="请输入付款账号" v-model="collectInfo.payAccount" class="input-with-select" style="width:480px">
                        <el-select v-model="collectInfo.payAccountType" slot="prepend" placeholder="付款方式" style="width:100px">
                        <el-option 
                        :label="item.value" 
                        :value="item.id"
                        v-for="item in payAccountTypeList"
                        :key="item.id"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item label="关联单号:">
                    <el-input :disabled='disabled' placeholder="关联单号" v-model="collectInfo.relatedBillNo" style="width:480px;margin-right:10px"/>
                </el-form-item>
                <el-form-item label="备注:">
                    <el-input placeholder="备注" v-model="collectInfo.tips" style="width:480px;margin-right:10px"/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="sure(boxType)">确 定</el-button>
            </span>
            <!-- 类型管理 -->
            <el-dialog
            width="40%"
            title="类型管理"
            :visible.sync="typeManage"
            append-to-body>
                <p style="margin-bottom:10px">所有类型</p>
                <div class="box">
                    <div :class="index<=1?'active type':'type'" v-for="(item,index) in collectionBillTypeList" :key="item.id" :disabled="index<2">
                        <p v-if="index<=1">{{item.value}}</p>
                        <input v-if="index>1" type="text" v-model="item.value" @change="typeChange(item.id)">
                        <i class="el-icon-close" v-show="index>1" @click="removeType(item.id)"></i>
                    </div>
                    <div class="type" v-show="minshow">
                        <input type="text" v-model="mintype.value" @blur="addType">
                        <i class="el-icon-close" @click="minshow=false"></i>
                    </div>
                </div>
                <el-button type="primary" plain icon="el-icon-plus" size="mini" style="margin-top:10px" @click="append">添加类型</el-button>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="typeManage = false">取 消</el-button>
                    <el-button type="primary" @click="typeManage = false">确 定</el-button>
                </span>
            </el-dialog>
        </el-dialog>
        <el-dialog
            title="驳回"
            :visible.sync="backBox"
            width="30%"
            :before-close="backBoxClose">
                <el-input
                type="textarea"
                :rows="5"
                placeholder="请输入驳回原因"
                v-model="uploadInfo.notPassReason"
                >
                </el-input>
                <div style="color:red;margin-top:10px">*  要求必须填写</div>
            <div slot="footer">
                <el-button @click="backBoxClose">取 消</el-button>
                <el-button :loading='dialogLoading' type="primary" @click="reject">确 定</el-button>
            </div>
        </el-dialog>
  </div>
</template>

<script>
import TableDialog from '@/components/tableDialog.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import {pageInit, listPage, batchUpdate, addInit, addCollect, del, changeInit, change, addtype, delType, checkDetail, exportExcel,exportRecort} from '@/api/collectionAccount.js'
export default {
    components:{
        TableDialog,
        ElImageViewer
    },
    data(){
        let mytoken=window.sessionStorage.getItem('token')
        return{
            dialogLoading:false,
            backBox:false,//驳回
            backReason:'',//驳回原因
            showBig:false,
            pickerOptions: {
                disabledDate(v) {
                    // return v.getTime() < new Date().getTime() - 8.64e6 ;不包今天
                    return v.getTime() < new Date(new Date(new Date().toLocaleDateString()).getTime())
                },
            },
            importHeaders: {
                 token: mytoken
            },
            // 查看详情
            detail:false,
            sign:false,
            loading:false,
            // sum:'',合计
            minshow:'',//新增类型框显示
            mintype:{
                id:'',
                value:''
            },
            fileList:[],//上传凭证列表
            typeManage:false,//类型管理
            boxType:'',//判断添加还是修改
            typeManage:false,
            addCollect:false,
            // 初始化内容
            payAccountList:[],//收款账户名称
            payAccountTypeList:[],//收款账户类型
            collectionBillStatusList:[],
            collectionBillTypeList:[],
            collectionSourceList:[],
            pageInfo:{
                status:'150',
                keyword:'',
                current:'1',
                collectionType:'',
                autoCreate:'',
                pageSize:''
            },
            total:'',
            tableData:[],
            tableHead:[],
            tableList:[
                {name:'收款单号',prop:'collectionNo',width:'155'},
                {name:'关联单号',prop:'relatedBillNo'},
                {name:'收款类型',prop:'collectionTypeName'},
                {name:'收款账户名称',prop:'financeAccountName'},
                {name:'状态',prop:'2'},
                {name:'应收金额RMB',prop:'receivableAmount'},
                {name:'币种',prop:'currencyName'},
                {name:'对应币种金额',prop:'amountInCurrency'},
                {name:'付款账户名称',prop:'payAccountName'},
                {name:'付款账号',prop:'payAccount'},
                {name:'预计收款时间',prop:'estimateCollectionDate'},
                {name:'创建人/创建时间',prop:'1',width:'200'},
                {name:'备注',prop:'tips'},
            ],
            collectInfoInfoRules:{
                collectionType:[{ required: true, message: '请选择收款类型', trigger: 'blur' }],
                amountInCurrency:[{required:true,message:'请输入应收金额',trigger:'blur'}],
                financeAccountId:[{required:true,message:'请选择收款账户名称',trigger:'blur'}],
                estimateCollectionDate:[{required:true,message:'请选择预计收款时间',trigger:'blur'}],
                payAccountName:[{required:true,message:'请输入付款账户名称',trigger:'blur'}],
                payAccount:[{required:true,message:'请输入收款账号',trigger:'blur'}],
            },
            collectInfo:{
                collectionType:'',//收款类型
                financeAccountName:'',//收款账户名称
                amountInCurrency:'',//应收金额
                estimateCollectionDate:'',//预计收款时间
                payAccountName:'',//付款账户名称
                payAccountType:'',//付款账户类型
                payAccount:'',//付款账号
                relatedBillNo:'',//关联单号
                tips:'',//备注
            },
            multipleSelection:[],
            uploadInfo:{
                ids:[],
                imageIds:[],
                status:'151',
                notPassReason:''
            },
            bigUrl:[],
            disabled:false,
            RmbRate:'',
            //汇率
            JpyRate:'',
            UsdRate:'',
            // 区分自动创建或手动创建
            sources:'',
            downReportBox:false,
            downList:[],
        }
    },
    computed:{
        action(){
            return process.env.VUE_APP_API_URL+'/api/file/upload/uploadFile'
        },
        currency(){
            let unit=''
            this.payAccountList.forEach(item=>{
                if(item.id==this.collectInfo.financeAccountId){
                    unit=item.currency
                }
            })
            return unit
        },
        turnRMB(){
            if(this.sources=='添加收款单'||this.sources=='手动创建'){
                if(this.currency=='RMB'){
                    return this.collectInfo.amountInCurrency
                }
                if(this.currency=='USD'){
                    return Math.round(this.collectInfo.amountInCurrency * this.UsdRate)/100
                }
                if(this.currency=='JPY'){
                    return Math.round(this.collectInfo.amountInCurrency * this.JpyRate)/100
                }
            }
            if(this.sources=='系统创建'){
                if(this.currency=='RMB'){
                    this.collectInfo.amountInCurrency=this.RmbRate
                }
                if(this.currency=='USD'){
                    this.collectInfo.amountInCurrency = Math.round(this.RmbRate / this.UsdRate*100*100)/100
                }
                if(this.currency=='JPY'){
                    this.collectInfo.amountInCurrency = Math.round(this.RmbRate / this.JpyRate*100*100)/100
                }
                return this.RmbRate
            }
        }
    },
    created(){
        this.tableHead=this.tableList
        this.PageInit()
        this.getPageInit()
    },
    methods:{
        closeViewer(){
            this.showBig = false;
        },
        //点击已上传的文件
        handlePreview(val){
            this.bigUrl=[val.url]
            this.showBig=true
        },
        //上传组件删除钩子
        handleRemove(file,fileList){
            this.fileList=fileList
        },
        handleClick(tab){
            this.pageInfo.current=1
            this.pageInfo.status=tab.name
            this.getPageInit()
        },
        // 查询初始化
        async PageInit(){
            const {data} = await pageInit() 
            this.collectionBillStatusList=data.data.collectionBillStatusList
            this.collectionBillTypeList=data.data.collectionBillTypeList
            this.collectionSourceList=data.data.collectionSourceList
        },
        // 分页信息查询
        async getPageInit(){
            this.loading=true
            const {data} = await listPage(this.pageInfo)
            this.tableData=data.values
            this.total=data.pageInfo.total
            // this.pageInfo.collectionType=''
            // this.pageInfo.keyword=''
            // this.pageInfo.autoCreate=''
            this.loading=false
            this.getSum()
        },
        getSum(){
            if(this.tableData.length==0){
                this.sum=0
            }else {
                const price=this.tableData.map(item=>{
                    return item.receivableAmount
                })
                this.sum=price.reduce((accumulator, currentValue)=>{
                    return accumulator+currentValue
                })
            }
        },
        setTableHead(data){
            this.tableHead=data
        },
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.getPageInit()
        },
        handleCurrentChange(val){
            this.pageInfo.current=val
            this.getPageInit()
        },
        handleSelectionChange(val){
            this.multipleSelection = val;
        },
        handleClose(){
            this.currency=''
            this.disabled=false
            this.addCollect=false
            this.sign=false
            this.fileList=[],
            this.collectInfo={
                collectionType:'',//收款类型
                financeAccountId:'',//收款账户名称
                receivableAmount:'',//应收金额
                estimateCollectionDate:'',//预计收款时间
                payAccountName:'',//付款账户名称
                payAccountType:'',//付款账户类型
                payAccount:'',//付款账号
                relatedBillNo:'',//关联单号
                tips:'',//备注
            }
            this.$refs.collectInfo.clearValidate()
        },
        uploading(response,file,fileList){
            // this.uploadInfo.imageIds.push(file.response.data.fileId)
            this.fileList=fileList
        },
        changeStatus(stu){
            if(this.multipleSelection.length<1){
                this.$message({
                    type:'warning',
                    message:'请选择内容'
                })
                return
            }
            this.fileList=[]
            this.uploadInfo.ids=this.multipleSelection.map(item=>{
                return item.id
            })
            this.sign=true
        },
        //确认上传
        affirm(){
            this.uploadInfo.imageIds=this.fileList.map(item=>{
                return item.response.data.fileId
            })
            this.dialogLoading=true
            batchUpdate(this.uploadInfo).then(res=>{
                if(res.data.code==200){
                    this.$message({
                        type:'success',
                        message:res.data.message
                    })
                    this.dialogLoading=false
                    this.sign=false
                    this.getPageInit()
                }else{
                    this.$message({
                        type:'warning',
                        message:'上传失败'
                    })
                    this.dialogLoading=false
                }
            })
        },
        // 查看凭证
        async findDetail(id){
            this.detail=true
            const {data} = await checkDetail(id)
            this.fileList=data.data
        },
        infoBox(data,id){
            this.boxType=data
            this.addCollect=true
            addInit().then(res=>{
                this.payAccountList=res.data.data.payAccountList
                this.payAccountTypeList=res.data.data.payAccountTypeList
                this.UsdRate=res.data.data.rateList[0].UsdRate
                this.JpyRate=res.data.data.rateList[0].JpyRate
            })
            if(data=='添加收款单'){
                this.sources=data
            }
            if(data=='修改'){
                changeInit(id).then(res=>{
                    this.collectInfo=res.data.data
                    //判断能否输入
                    if(res.data.data.autoCreate==1){
                        this.sources='系统创建'
                        this.disabled=true
                    }else{
                        this.sources='手动创建'
                        this.disabled=false
                    }
                    //应收金额转换
                    this.RmbRate=res.data.data.receivableAmount
                    // if(this.currency=='RMB'){
                    //     this.RmbRate=res.data.data.amountInCurrency
                    // }
                    // if(this.currency=='USD'){
                    //     this.RmbRate=Math.floor(res.data.data.amountInCurrency * (this.UsdRate/100)*100) / 100
                    // }
                    // if(this.currency=='JPY'){
                    //     this.RmbRate=Math.floor(res.data.data.amountInCurrency *(this.JpyRate/100)*100) / 100
                    // }
                })
            }
        },
        sure(data){
            if(this.collectInfo.payAccountType==''){
                 this.$message({
                    type:'warning',
                    message:'请选择付款方式'
                })
                return
            }           
            this.$refs.collectInfo.validate(valid=>{
                if(valid){
                    this.dialogLoading=true
                    if(data=='添加收款单'){
                        addCollect(this.collectInfo).then(res=>{
                            this.dialogLoading=false
                            if(res.status==200){
                                this.$message({
                                    type:'success',
                                    message:res.data.message
                                })
                                this.addCollect=false
                                this.getPageInit()
                                this.disabled=false
                            }else {
                                this.$message({
                                    type:'warning',
                                    message:'新增失败'
                                })
                                return
                            }
                        })
                    }
                    if(data=='修改'){
                        change(this.collectInfo).then(res=>{
                            if(res.status==200){
                                this.$message({
                                    type:'success',
                                    message:'修改成功'
                                })
                                this.addCollect=false
                                this.getPageInit()
                                this.disabled=false
                                this.collectInfo={
                                    collectionType:'',//收款类型
                                    financeAccountId:'',//收款账户名称
                                    receivableAmount:'',//应收金额
                                    estimateCollectionDate:'',//预计收款时间
                                    payAccountName:'',//付款账户名称
                                    payAccountType:'',//付款账户类型
                                    payAccount:'',//付款账号
                                    relatedBillNo:'',//关联单号
                                    tips:'',//备注
                                }
                            }else {
                                this.$message({
                                    type:'warning',
                                    message:'修改失败'
                                })
                            }
                        })
                    }
                }else {
                    console.log('不满足');
                }
            })
        },
        remove(id){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const {data} =await del(id)
                if(data.code==200){
                    this.$message({
                        message:data.message,
                        type: 'success'
                    })
                    this.getPageInit()
                    if(this.tableData.length=1){
                        this.pageInfo.current=this.pageInfo.current-1
                        this.getPageInit()
                    }
                }else{
                    this.$message({
                        message:'网络超时',
                        type: 'warning'
                    });
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        addType(){
            if(!this.mintype.value){
                this.$message({
                    type:'warning',
                    message:'请输入内容'
                })
                this.minshow=false
                return
            }
            addtype(this.mintype).then(res=>{
                if(res.data.code==200){
                    this.$message({
                        type:'success',
                        message:'添加类型成功'
                    })
                }
                this.minshow=false
                this.PageInit()
            })
        },
        append(){
            this.minshow=true
            this.mintype={id:'',value:''}
        },
        removeType(ind){
            this.$confirm('此操作将永久删除该类型, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delType(ind).then(res=>{
                    if(res.data.code==200){
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }else{
                        this.$message({
                            type: 'warning',
                            message: res.data.message
                        });
                    }
                    this.PageInit()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });     
                this.PageInit()    
            });
        },
        // 类型修改
        typeChange(id){
            this.collectionBillTypeList.forEach(item=>{
                if(id==item.id){
                    this.$confirm('此操作将修改此类, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        addtype({id:id,value:item.value}).then(res=>{
                            this.PageInit()
                            if(res.data.code==200){
                                this.$message({
                                    type: 'success',
                                    message: '修改成功!'
                                });
                            }else {
                                this.$message({
                                    type:'warning',
                                    message:res.data.message
                                })
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消修改'
                        });
                        this.PageInit()          
                    });
                }
            })
        },
        // 驳回
        back(id){
            this.uploadInfo.ids=[id]
            this.backBox=true
        },
        // 确定驳回
        reject(){
            if(!this.uploadInfo.notPassReason){
                this.$message({
                    type:'warning',
                    message:'请输入驳回原因'
                })
                return
            }
            this.dialogLoading=true
            batchUpdate({ids:this.uploadInfo.ids,notPassReason:this.uploadInfo.notPassReason,status:'152'}).then(res=>{
                this.dialogLoading=false
                if(res.data.code==200){
                    this.$message({
                        type:'success',
                        message:res.data.message
                    })
                    this.getPageInit()
                    this.backBox=false
                    this.uploadInfo.notPassReason=''
                }else{
                    this.$message.warning(res.data.message)
                    this.dialogLoading=false
                }
            })
        },
        backBoxClose(){
        this.uploadInfo.notPassReason=''
        this.backBox=false
        },
        //导出
        exportTable(){
            this.downReportBox=true
            exportRecort().then(res=>{
                // if(res.data.code==200){
                    this.downList=res.data.data.data
                // }
            })
        },
        report(){
            this.$confirm('确认生成收款单报告？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                exportExcel({
                    autoCreate:this.pageInfo.autoCreate,
                    status:this.pageInfo.status,
                    collectionType:this.pageInfo.collectionType,
                    keyword:this.pageInfo.keyword
                })
                this.$alert('导出中，请稍等数分钟后下载，您可以在“下载报告中”查看情况并下载', '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        // this.$message({
                        // type: 'info',
                        // message: `action: ${ action }`
                        // });
                        return ;
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消生成'
                });          
            });
        },
        down(url){
            const link = document.createElement('a')
            document.body.appendChild(link)
            link.href =url
            link.click()
            document.body.removeChild(link)
        },
        search(){
            this.pageInfo.current=1
            this.getPageInit()
        }
    }
}
</script>

<style scoped lang="scss">
.setting{
    color:#409EFF;
    font-size:25px;
    border:2px solid #409EFF;
    border-radius: 6px;
    overflow: hidden;
    width: 25px;
    height: 25px;
}
.type{
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;
    width: 107px;
    height: 30px;
    border-radius: 4px;
    border: 1px #E4E7ED solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    input{
        border: none;
        width: 90px;
        outline: none;
        background: transparent;
        font-size: 14px;
        text-align: center;
        color: #606266;
    }
    p{
        text-align: center;
        width: 97px;
    }
    i{
        color: gray;
    }
}
.box{
    display: flex;
    flex-wrap: wrap;
}
.active{
    background: #DCDFE6;
}
.sum{
    background: #E4E7ED;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;
}
.demo-image__preview{
    .el-image{
        margin-right: 20px;
    }
}

</style>